module.exports = {
  language: {
    name: 'CN'
  },
  "header": {
    home: "home",
    productCenter: "productCenter",
    solution: "solution",
    newsCenter: "newsCenter",
    joinUs: "joinUs",
    contactUs: "contactUs",
  },
  "footer": {
    a: "AMANO KENKI Co., Ltd",
    b: "726-2 Takucho, Taku City, Saga Prefecture ",
    c: "TEL: 0952-97-9090",
    d: "Closed on Wednesdays ・ year-end and New Year holidaysく / 09:00～18:00",
  },
  "home": {
    a: "Specialize we sell and export Marine Diesel Engine！",
    b: "Closed on Wednesdays ・ year-end and New Year holidaysく / 09:00～18:00",
    c: "Our company was established in 2014, and has been exporting construction machinery, marine engines, etc. to Europe, Dubai, Vietnam, Mongolia, etc. Please feel free to contact us when you sell construction machinery, ship engines, etc.",
    d: "Used Engine",
    e: "Marine Item",
    f: "Used Construction",
    g: "Information",
    h: "Overseas customers",
    i: "We are exporting Used Boat and Used Marine Diesel Engine direct from Japan.Specialize we sell and export “Japan Brand” Marine Diesel Engine, YANMAR, MITSUBISHI, YAMAHA, ISUZU, KOMATSU and Volvo etc.",
    j: "Easy! Flow until sales!",
    k: "Our Policy Please make full payment in advance for your reservation. No Refunds, No Return, No exchange, the sale is final, AS IS.",
    l: "Application Estimate",
    m: "Contract",
    n: "Payment",
    o: "Shipping",
    p: "E-mail Estimate",
    q: "Reception time：09:00～18:00",
    r: "（Closed on Wednesdays）",
    s: "LINE Estimate",
    t: "Reception time：09:00～18:00",
    u: "（Closed on Wednesdays）",
  },
  "consultation": {
    a: "Your best choice！",
    b: "Contact Us・Please feel free to contact us for advice.",
    c: "For consultation by phone",
    d: "Closed on Wednesdays ・ year-end and New Year holidaysく / 09:00～18:00",
    e: "For consultation on SNS",
    f: "Other Inquiries",
    g: "first name",
    h: "Last name",
    i: "E-mail",
    j: "location",
    k: "TEL Number",
    l: "Your business",
    m: "SEND",
  },
  "companyIntroduction": {
    a: "AMANO KENKI Co., Ltd. was established in 2014 and exports construction machinery, marine engines, etc. to Europe, Dubai, Vietnam, Mongolia, etc. Please feel free to contact us when you sell construction machinery, ship engines, etc.",
    b: "About Us",
    descriptionList: [
      { label: "Company Name", value: "AMANO KENKI Co., Ltd" },
      { label: "Foundation", value: "2014年" },
      { label: "location", value: "726-2 Takucho, Taku City, Saga Prefecture" },
      { label: "TEL", value: "0952-97-9090" },
      { label: "定休日", value: "Closed on Wednesdays" },
      { label: "business hours", value: "09:00～18:00" },
      { label: "WEBSITE", value: "https://www.amanokenki.com/" },
    ]
  },
  productList: {
    a: "キーワード、エンジン名で探す",
  },
}
