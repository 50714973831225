module.exports = {
  language: {
    name: 'EN'
  },
  "header": {
    home: "关于合力",
    productCenter: "产品中心",
    solution: "解决方案",
    newsCenter: "新闻中心",
    joinUs: "加入我们",
    contactUs: "联系我们",
  },
  "footer": {
    a: "关注或联系我们",
    b: "咨询热线",
    c: "公司公共邮箱",
    d: "人力资源邮箱",
    e: "商务采购邮箱",
    f: "公司地址",
    g: "山西综改示范区南中环街529号清控创新基地C座19层",
  },
  "home": {
    a: "企业简介",
    b: "山西合力创新科技股份有限公司成立于2005年【股票代码871229】，是一家从事电力行业专业软件研发、ICT 信息化系统集成、电力能源综合服务、运维服务产品化的高新技术企业。公司致力于为电力行业发、输、配、售、用全产业链条相关专业提供专业的系统解决方案及咨询、运维服务。",
    c: "公司自成立以来，秉承“至诚专业”的企业精神，扎根山西，聚焦电力，为国家电网山西省公司及200+发电企业提供了系统集成、建设、运维及咨询等服务。公司多年来持续深耕电力行业，自主研发搭建了山西省电力调度省、地一体化综合智能数据网监控及管理服务平台，为山西电网的建设和安全运行提供了强有力的支撑和保障；公司研发的电网技术中心数字化平台，为国网山西省电科院省公司的各类支撑工作及新能源技术服务实现跨专业、跨业务的高效协作保驾护航，增强了国网山西省电科院的科技管理及创新能力；为国网山西省运检开发的基于增强现实(AR)技术的移动运检系统，提升了变电站运检作业的智能化水平。",
    d: "中古エンジン",
    e: "マリン用品",
    f: "中古建機",
    g: "高額買取",
    h: "当社が高額買取できる理由",
    i: "輸出先の現地業社と直接取引をするので、中間マージンを大幅に省くことができるので コストパフォーマンスが高い！中間バイヤーや輸出事業者や現地コンサル会社などを通さない独自のルートを持っております",
    j: "かんたん！買取までの流れ！",
    k: "買い取りの場合、まず当社がお客様の船舶・エンジン・建機などの査定を行い、買い取り金額のご提示をいたします。船舶・エンジン・建機の状態によっては買い取りをご遠慮する場合もございます。ご了承ください。",
    l: "お申込み 見積もり",
    m: "ご契約",
    n: "引き取り 持ち込み",
    o: "お支払い 売却完了",
    p: "E-mailでお見積り",
    q: "受付時間：09:00～18:00",
    r: "（定休日：水曜日）",
    s: "LINEでお見積り",
    t: "受付時間：09:00～18:00",
    u: "（定休日：水曜日）",
  },
  "consultation": {
    a: "あなたの最良の選択！",
    b: "お問い合せ・ご相談などお気軽にどうぞ​",
    c: "お電話でのご相談の方",
    d: "定休日水曜日・年末年始を除く / 09:00～18:00",
    e: "SNSでのご相談の方",
    f: "その他のお問い合わせ",
    g: "お名前",
    h: "お名前（フリガナ）",
    i: "E-mail",
    j: "住所",
    k: "お電話番号",
    l: "ご用件",
    m: "送信",
  },
  "companyIntroduction": {
    a: "AMANO KENKI株式会社は平成２６年に会社を設立し、建設機械、船舶エンジンなど をヨーロッパ・ドバイ・ベドナム・モンゴルなどに輸出しております。建設機械、 船舶エンジンなどをお売りの際は、ぜひ弊社までご連絡ください。",
    b: "会社情報",
    descriptionList: [
      { label: "社名", value: "AMANO KENKI 株式会社" },
      { label: "創業", value: "2014年" },
      { label: "所在地", value: "佐賀県多久市多久町726-2" },
      { label: "連絡先", value: "0952-97-9090" },
      { label: "定休日", value: "年末年始 / 水曜日" },
      { label: "営業時間", value: "09:00～18:00" },
      { label: "WEBSITE", value: "https://www.amanokenki.com/" },
    ]
  },
  productList: {
    a: "Search by keyword or engine name",
  },
}
